import axios from 'axios';

import * as actionTypes from "../actionTypes";

export const login = (email, password) => {
    return async (dispatch, getState) => {
        const url = getState().config.url;
        try{
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);

            const response = await axios.post(
                `${url}/conductor/login`, formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json'
                    }
                }
            );
            await dispatch(getProfile(response.data.data.tokenType, response.data.data.accessToken));
            await dispatch(authenticate(response.data.data.tokenType, response.data.data.accessToken));
        }catch(error){
            let message = error?.response?.data?.message ?? 'Internal Server Error';
            if(Object.isExtensible(error?.response?.data?.message ?? message)){
                let key = Object.keys(error?.response?.data?.message)[0];
                message = error?.response?.data?.message[key];
            }
            throw new Error(message);
        }
    }
}

export const authenticate = (tokenType, token) => {
    return {
        type: actionTypes.AUTHENTICATE,
        token: token,
        tokenType: tokenType
    }
}

export const getProfile = (paramTokenType, paramToken) => {
    return async (dispatch, getState) => {
        const url = getState().config.url;
        const tokenType = paramTokenType? paramTokenType : getState().auth.tokenType;
        const token = paramToken? paramToken : getState().auth.token;
        try{
            const response = await axios.get(
                `${url}/conductor/account`,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json',
                        'Authorization': `${tokenType} ${token}`
                    }
                }
            );
            let profile = {
                id: response.data.data.id,
                name: response.data.data.name,
                phone: response.data.data.phone,
                image: response.data.data.image_url,
                requiredAction: response.data.data.required_action,
            }
            await dispatch({
                type: actionTypes.GET_DATA_PROFILE,
                profile: profile
            })
        }catch(error){
            let message = error?.response?.data?.message ?? 'Internal Server Error';
            if(error?.response?.status === 401){
                await dispatch({ type: actionTypes.LOGOUT })
                return error?.response;
            }else {
                if(Object.isExtensible(error?.response?.data?.message ?? message)){
                    let key = Object.keys(error?.response?.data?.message)[0];
                    message = error?.response?.data?.message[key];
                }
            }
            throw new Error(message);
        }
    }
}

export const logout = () => {
    return async (dispatch, getState) => {
        const url = getState().config.url;
        const tokenType = getState().auth.tokenType;
        const token = getState().auth.token;
        dispatch({ type: actionTypes.LOGOUT })
        try{
            await axios.post(
                `${url}/conductor/logout`,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json',
                        'Authorization': `${tokenType} ${token}`
                    }
                }
            );
            await dispatch({ type: actionTypes.LOGOUT })
        }catch(error){
            let message = error?.response?.data?.message ?? 'Internal Server Error';
            if(error?.response?.status === 401){
                await dispatch({ type: actionTypes.LOGOUT })
                return error?.response;
            }else {
                if(Object.isExtensible(error?.response?.data?.message ?? message)){
                    let key = Object.keys(error?.response?.data?.message)[0];
                    message = error?.response?.data?.message[key];
                }
            }
            throw new Error(message);
        }
    }
}

export const changeFirstLoggedIn = (isFirstLoggedIn) => {
    return {
        type: actionTypes.CHANGE_FIRST_LOGGEDIN,
        isFirstLoggedIn: isFirstLoggedIn
    }
}
