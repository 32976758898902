export const formatDate = (date, lengthMonth) => {
    let nDate = new Date(date);
    let day = nDate.getDate().toString();
    day = day.length > 1? day : "0" + day;
    let month = nDate.getMonth();
    const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
    month = months[month].substr(0, lengthMonth? lengthMonth : months[month].length);
    return `${day} ${month} ${nDate.getFullYear()}`
}

export const formatTime = (date) => {
    let nDate = new Date(date);
    let hour = nDate.getHours().toString();
    hour = hour.length > 1? hour : "0" + hour;
    let minute = nDate.getMinutes().toString();
    minute = minute.length > 1? minute : "0" + minute;
    return `${hour}:${minute}`
}

export const formatRupiah = (number, separator) => {
    let newNumber = number.toString();
    let isMinus = newNumber[0] === '-';
    let nNumber = isMinus? newNumber.substr(1, newNumber.length - 1) : newNumber;
    nNumber = Math.round(nNumber);
    let	numberString = nNumber.toString();
    // let decimal = numberString.split('.')[1]? (numberString.split('.')[1] === "00"? null : numberString.split('.')[1]) : null;
    numberString = numberString.split('.')[0];
	let sisa = numberString.length % 3;
	let currency = numberString.substr(0, sisa);
    let ribuan = numberString.substr(sisa).match(/\d{3}/g);
    
    if (ribuan) {
        let separate = sisa ? separator : '';
        currency += separate + ribuan.join(separator);
    }

    return `${isMinus? '- Rp ' : 'Rp '}${currency}`;
}