import axios from 'axios';
import * as actionTypes from '../actionTypes';

export const getDetailAuction = (id) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.get(`${url}/conductor/v2/detail-auction/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `${tokenType} ${token}`,
        },
      });
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = error?.response?.data?.message ?? 'Internal Server Error';
      if (Object.isExtensible(error?.response?.data?.message ?? message)) {
        let key = Object.keys(error?.response?.data?.message)[0];
        message = error?.response?.data?.message[key];
      }
      throw Object.assign(new Error(message), { code: error?.response?.status, status: 'error' });
    }
  };
};

export const store = () => {
  return (dispatch, getState) => {
    return getState;
  };
};

export const getDetailUnit = (idAuction, idUnit) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.get(`${url}/conductor/v2/detail-auction/${idAuction}/detail-unit/${idUnit}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `${tokenType} ${token}`,
        },
      });
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = error?.response?.data?.message ?? 'Internal Server Error';
      if (Object.isExtensible(error?.response?.data?.message ?? message)) {
        let key = Object.keys(error?.response?.data?.message)[0];
        message = error?.response?.data?.message[key];
      }
      throw Object.assign(new Error(message), { code: error?.response?.status, status: 'error' });
    }
  };
};

export const getNextLot = (idAuction, idUnit) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.get(`${url}/conductor/v2/detail-auction/${idAuction}/next-lot/${idUnit}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `${tokenType} ${token}`,
        },
      });
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = error?.response?.data?.message ?? 'Internal Server Error';
      if (Object.isExtensible(error?.response?.data?.message ?? message)) {
        let key = Object.keys(error?.response?.data?.message)[0];
        message = error?.response?.data?.message[key];
      }
      throw Object.assign(new Error(message), { code: error?.response?.status, status: 'error' });
    }
  };
};

export const getBidding = (idAuction, idUnit) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.get(`${url}/conductor/v2/detail-auction/${idAuction}/detail-bidder/${idUnit}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `${tokenType} ${token}`,
        },
      });
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = error?.response?.data?.message ?? 'Internal Server Error';
      if (Object.isExtensible(error?.response?.data?.message ?? message)) {
        let key = Object.keys(error?.response?.data?.message)[0];
        message = error?.response?.data?.message[key];
      }
      throw Object.assign(new Error(message), { code: error?.response?.status, status: 'error' });
    }
  };
};

export const getOfflineNipl = (idAuction) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.get(`${url}/conductor/v2/detail-auction/${idAuction}/offline-bidder`, {
        headers: {
          Accept: 'application/json',
          Authorization: `${tokenType} ${token}`,
        },
      });
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};

export const startAuction = (idAuction, unitId) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.post(
        `${url}/conductor/v2/auction/${idAuction}/start`,
        { unit_id: unitId },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `${tokenType} ${token}`,
          },
        }
      );
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};

export const startPendingAuction = (idAuction, unitId) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.post(
        `${url}/conductor/v2/auction/${idAuction}/start-pending`,
        { unit_id: unitId },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `${tokenType} ${token}`,
          },
        }
      );
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};

export const pendingAuction = (idAuction) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.post(`${url}/conductor/v2/auction/${idAuction}/pending`, null, {
        headers: {
          Accept: 'application/json',
          Authorization: `${tokenType} ${token}`,
        },
      });
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};

export const finishAuction = (idAuction) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.post(`${url}/conductor/v2/auction/${idAuction}/stop`, null, {
        headers: {
          Accept: 'application/json',
          Authorization: `${tokenType} ${token}`,
        },
      });
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};

export const submitBid = (idAuction, offerPrice) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.post(
        `${url}/conductor/v2/auction/${idAuction}/bid`,
        { offer: offerPrice },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `${tokenType} ${token}`,
          },
        }
      );
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};

export const auctionAutopilot = (id) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.post(
        `${url}/conductor/v2/auction/${id}/autopilot`,
        {},
        {
          headers: {
            Accept: 'application/json',
            Authorization: `${tokenType} ${token}`,
          },
        }
      );
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};

export const notSold = (idAuction) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.post(
        `${url}/conductor/v2/auction/${idAuction}/not-sold`,
        { is_autopilot: false },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `${tokenType} ${token}`,
          },
        }
      );
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};

export const sold = (idAuction, buyerNiplId) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.post(
        `${url}/conductor/v2/auction/${idAuction}/sold`,
        { buyer_nipl_id: buyerNiplId, is_autopilot: false },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `${tokenType} ${token}`,
          },
        }
      );
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};

export const floor = (idAuction) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.post(`${url}/conductor/v2/auction/${idAuction}/floor`, null, {
        headers: {
          Accept: 'application/json',
          Authorization: `${tokenType} ${token}`,
        },
      });
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};

export const finishSold = (idAuction, buyerNiplId) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.post(
        `${url}/conductor/v2/auction/${idAuction}/finish-sold`,
        { buyer_nipl_id: buyerNiplId },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `${tokenType} ${token}`,
          },
        }
      );
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};

export const getProxyBid = (idAuction, idUnit) => {
  return async (dispatch, getState) => {
    const url = getState().config.url;
    const tokenType = getState().auth.tokenType;
    const token = getState().auth.token;

    try {
      const response = await axios.get(`${url}/conductor/auction/${idAuction}/unit/${idUnit}/proxy-bidder`, {
        headers: {
          Accept: 'application/json',
          Authorization: `${tokenType} ${token}`,
        },
      });
      return { status: 'success', data: response.data };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error?.response?.status === 401) {
          await dispatch({ type: actionTypes.LOGOUT });
          return error?.response;
        } else if (error.response.data.errors) {
          message = error.response.data.errors.details;
        }
      } else {
        message = error.message;
      }
      return { status: 'error', data: message };
    }
  };
};
