import React, { useState } from "react";
import Select from 'react-select';
import { ReactComponent as StopFill } from '../assets/icons/stop-circle-fill.svg';

import Button from "./Button";

const ModalPilihPemenang = props => {

    const { show, body, onSubmit } = props;
    
    const [winner, setWinner] = useState();

    const options = body?.map(option => {
        return {
            value: option.id,
            label: `${option.nipl} - ${option.buyer?.user?.name} (${option.buyer?.user?.email})`
        }
    })

    return (
        show ? (
            <>
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-sourceSansPro"
                >
                    <div className="relative w-[350px] my-2 mx-auto max-w-3xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="self-center mt-6 mb-2">
                                <StopFill 
                                    width={80}
                                />
                            </div>
                            <div className="flex items-start justify-center pb-2 border-solid border-slate-200 rounded-t p-2">
                                <h3 className="text-2xl font-sourceSansPro font-semibold text-center">Lelang diberhentikan & Unit telah terjual</h3>
                            </div>
                            <p className="px-2 pb-6 text-center text-sm text-[#646566]">Unit dianggap terjual karena telah ada bidder yang melakukan bid. Silahkan pilih pemenang.</p>
                            {body && <div className="relative px-4 pb-4 flex-auto max-w-80">
                                <div className="mb-2 font-semibold">NIPL Pemenang <span className="text-primary">*</span></div>
                                <Select 
                                    className="text-left"
                                    onChange={(data) => setWinner(data)}
                                    // value={winner}
                                    placeholder='Pilih NIPL Pemenang'
                                    options={options}
                                    isClearable
                                />
                            </div>}
                            <div className="flex flex-col py-4 rounded-b w-80 self-center">
                                <Button 
                                    disabled={!winner}
                                    className="py-1 mb-2"
                                    title={"Submit Pemenang"}
                                    onClick={() => {winner && onSubmit(winner.value)}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null
    );
}

export default ModalPilihPemenang;