import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from 'react-router-dom'

import { store, persistor } from './redux';
import Router from './routes';

import * as Sentry from "@sentry/react";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default Sentry.withProfiler(App);
