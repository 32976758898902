// import * as actionTypes from '../actionTypes';

export const initialState = {
    url: process.env.REACT_APP_BASE_URL,
    wsKey: '1467551',
    wsCluster: 'ap1',
    pages: {
        dashboard: 'atm',
        konfigurasi: 'akun',
        master: 'user'
    }
}

const configReducer = (state = initialState, action) => {
    switch(action.type){
        default: {
            return state;
        }
    }
}

export default configReducer;