import React from "react";
import { ReactComponent as Close } from "../assets/icons/close.svg";

const ModalComponent = ({ show, closeModal, title, body, className }) => {
  return show ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-sourceSansPro">
        <div className={`relative my-6 mx-auto max-w-3xl ${className}`}>
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {title && (
              <div className="flex items-center justify-between px-6 py-3 border-solid border-slate-200 rounded-t border-b">
                <h3 className="text-lg font-sourceSansPro font-semibold text-center">
                  {title}
                </h3>
                <Close
                  className={
                    "hover:cursor-pointer hover:text-primary hover:stroke-primary"
                  }
                  onClick={closeModal}
                />
              </div>
            )}
            {body}
          </div>
        </div>
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null;
};

export default ModalComponent;
