import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DotLoaderOverlay } from 'react-spinner-overlay';
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as Play } from '../../assets/icons/play-circle-line.svg';
import { ReactComponent as Pause } from '../../assets/icons/pause-circle-line.svg';
import { ReactComponent as Stop } from '../../assets/icons/stop-circle-line.svg';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import MediaQuery from 'react-responsive';

import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { formatDate, formatRupiah } from '../../helpers/functionFormat';
import {
  finishAuction,
  getDetailAuction,
  getDetailUnit,
  getNextLot,
  getBidding,
  getOfflineNipl,
  startAuction,
  pendingAuction,
  startPendingAuction,
  submitBid,
  sold,
  notSold,
  floor,
  finishSold,
  logout,
} from '../../redux/actions';
import ModalPilihPemenang from '../../components/ModalPilihPemenang';
import ModalHentikanLelang from '../../components/ModalHentikanLelang';
import { ReactComponent as SpecialNiplIcon } from '../../assets/icons/honour-fill.svg';
import ToggleSwitch from '../../components/Switch';
import { auctionAutopilot } from '../../redux/actions/auction';

class LiveBidding extends Component {
  _mounted = true;
  intervalCountDown;

  state = {
    modal: {
      title: '',
      buttonText: '',
      body: '',
      show: false,
      onSubmit: () => {},
    },
    modalPemenang: {
      body: [],
      show: false,
      onSubmit: (buyer_nipl_id) => {},
    },
    modalHentikan: {
      body: [],
      show: false,
      type: '',
      onSubmit: (buyer_nipl_id) => {},
    },
    data: {},
    nextBid: 0,
    multipleBid: 0,
    auction: {},
    listLot: [],
    currentBidderType: '',
    buttonDisabled: true,
    countDown: {
      minutes: 0,
      seconds: 0,
      run: false,
    },
    lotSplice: {
      list: [],
      currentSplice: 0,
    },
    curIndex: 0,
    currentUnit: null,
    currentPrice: 0,
    bidder: [],
    proxyBidder: [],
    status: 'COMING',
    key: 1,
    idLotManual: '',
    loading: false,
    isAutopilot: false,
    disabledHentikan: true,
  };

  proxy = [];
  bidding = [];

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidMount() {
    // console.log(Pusher.name);
    this._isMounted = true;
    if (this._isMounted) {
      if (this.props.isFirstLoggedIn) {
        this.props.onChangeFirstLoggedIn();
      }
    }
    this.loadData();

    const id = this.props.match.params.id;

    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.REACT_APP_WS_KEY,
      cluster: process.env.REACT_APP_WS_CLUSTER,
      forceTLS: false,
      authEndpoint: `${this.props.url}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: this.props.tokenType + ' ' + this.props.token,
          Accept: 'application/json',
        },
      },
    });

    window.Echo.private(`auction.${id}`)
      .listen(`.auction.all`, (e) => {
        // console.log('auction.all');
        this.updateAll();
      })
      .listen(`.auction.bidder`, (e) => {
        this.addBidder(e);
        this.updateAll();
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.lotSplice.currentSplice !== this.state.lotSplice.currentSplice) {
      this.setState({
        lotSplice: {
          ...this.state.lotSplice,
          list: this.state.listLot.slice(this.state.lotSplice.currentSplice, 3 + this.state.lotSplice.currentSplice),
        },
      });
    }
    if (prevState.countDown.run !== this.state.countDown.run) {
      if (this.state.countDown.minutes === 0 && this.state.countDown.seconds === 0) {
        clearInterval(this.intervalCountDown);
        // if (this.state.isAutopilot) {
        //   this.onFinishAutomation();
        // }
        return;
      }
      if (!this.state.countDown.run) {
        clearInterval(this.intervalCountDown);
        return;
      }

      if (this.state.countDown.run) {
        if (this.state.auction.current_stock_unit.number_of_nipl === 1) {
          this.setState({ buttonDisabled: false });
        }

        this.intervalCountDown = setInterval(() => {
          if (this.state.countDown.minutes === 0 && this.state.countDown.seconds === 0) {
            this.setState({
              countDown: {
                ...this.state.countDown,
                run: false,
              },
            });
            return;
          }

          if (this.state.countDown.minutes === 0 && this.state.countDown.seconds <= 3) {
            this.setState({ buttonDisabled: true });
          }

          if (this.state.countDown.seconds > 0) {
            this.setState((prevState) => {
              return {
                ...prevState,
                countDown: {
                  ...prevState.countDown,
                  seconds: prevState.countDown.seconds - 1,
                },
              };
            });
          } else {
            this.setState({
              countDown: {
                ...this.state.countDown,
                minutes: this.state.countDown.minutes - 1,
                seconds: 59,
              },
            });
          }
        }, 1000);
      }
    }
  }

  updateAll = async () => {
    try {
      // console.log('all');
      const id = this.props.match.params.id;

      const auction = await this.props.onGetDetailAuction(id);
      // console.log('Auction', auction);
      if (auction?.data.data?.status === 'COMING') {
        this.setState({
          disabledHentikan: false,
        });
      }
      if (this._mounted) {
        this.setState({
          data: auction.data.data,
          auction: auction.data.data,
          status: auction.data.data?.status,
          currentBidderType: null,
          proxyBidder: auction.data.data?.proxy_bid,
          isAutopilot: auction.data?.data?.is_autopilot,
          // status: 'STARTED'
        });
      }
      const lot = await this.props.onGetNextLot(id, auction.data.data.current_stock_unit_id);
      if (this._mounted) {
        this.setState({
          listLot: lot.data.data,
          lotSplice: {
            list: lot.data.data.slice(0, 3),
            currentSplice: 0,
          },
        });
      }
      if (auction.data.data.current_stock_unit) {
        if (auction.data.data.current_stock_unit.number_of_nipl > 1) {
          this.setState({ buttonDisabled: true });
        }

        if (auction.data.data.status === 'CLOSED' && this.state.bidder.length > 0) {
          this.setFinishSold(id);
        }
        if (auction.data.data.status !== 'CLOSED') {
          const unitType = await this.props.onGetDetailUnit(id, auction.data.data.current_stock_unit_id);

          if (this._mounted) {
            this.setState({
              currentUnit: unitType.data.data,
            });

            if (unitType.data.data?.unit_auction_status === 'NOT_SOLD') {
              // disini munculin popup unit tidak terjual
              this.setState({ loading: true });
              this.setState({
                modal: {
                  show: true,
                  title: 'Unit Tidak terjual',
                  buttonText: 'OK',
                  cancelText: false,
                  onSubmit: () => {
                    this.setState({
                      modal: {
                        ...this.state.modal,
                        show: false,
                      },
                    });
                  },
                },
              });
            } else if (unitType.data.data?.unit_auction_status === 'SOLD') {
              this.setState({ loading: true });
              // disini munculin popup unit terjual
              this.setState({
                loading: false,
                modal: {
                  show: true,
                  title: 'Unit Terjual',
                  buttonText: 'OK',
                  cancelText: false,
                  onSubmit: () => {
                    this.setState({
                      modal: {
                        ...this.state.modal,
                        show: false,
                      },
                    });
                  },
                },
              });
            } else {
              this.setState({ loading: false });
            }

            await this.updateBidder(id, auction.data.data.current_stock_unit_id);
          }
        } else {
          this.setState({
            currentUnit: lot.data.data[0],
          });
          this.setState({ loading: false });
        }
      } else {
        this.setState({
          currentUnit: lot.data.data[0],
        });
        this.setState({ loading: false });
      }
    } catch (error) {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: error?.code === 401,
            title: 'Error',
            body: error?.message,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: async () => {
              if (error?.code === 401) {
                this.props.history.replace('/logout');
              }
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  toggleAutopilot = async (id) => {
    this.setState({
      loading: true,
    });
    try {
      const response = await this.props.onAuctionAutopilot(id);
      this.setState({
        isAutopilot: response.data.data?.is_autopilot,
        loading: false,
      });
    } catch (error) {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: error?.message,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: async () => {
              if (error?.code === 401) {
                this.props.history.replace('/logout');
              }
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  addBidder = (data) => {
    try {
      const time_diff = data?.time_diff;
      // console.log('DATA BIDDING', data);

      const dataList = data?.auction_progress;
      dataList.offer_price = parseInt(dataList?.offer_price, 10);

      let nextBid = parseInt(data?.auction_progress?.offer_price);
      if (dataList) {
        nextBid += parseInt(data?.unit_type?.multiple_bid) || 0;
      }
      // console.log('nextBid', nextBid);

      this.setState({
        bidder: data?.source === 'BID' ? [dataList, ...this.state.bidder] : [dataList, ...this.state.bidder.slice(1)],
        nextBid: nextBid,
        currentPrice: parseInt(data?.last_price, 10) || 0,
        multipleBid: data?.unit_type?.multiple_bid || 0,
        currentBidderType: data?.current_nipl_type || null,
        key: this.state.key + 1,
        countDown: this.secondsToTime(time_diff),
      });
    } catch (error) {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: error?.message,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: async () => {
              if (error?.code === 401) {
                this.props.history.replace('/logout');
              }
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  updateBidder = async (id, current_stock_unit_id) => {
    const listBidder = await this.props.onGetBidding(id, current_stock_unit_id);
    try {
      const time_diff = listBidder.data.data?.time_diff;

      // console.log('LIST BIDDER', listBidder);

      const dataList = listBidder.data.data?.auction_progresses?.map((el) => ({
        ...el,
        offer_price: parseInt(el.offer_price),
      }));

      let nextBid = parseInt(listBidder.data.data.last_price);
      if (dataList.length > 0) {
        nextBid += parseInt(listBidder.data.data?.unit_type?.multiple_bid) || 0;
      }

      // console.log(time_diff);

      this.setState({
        bidder: dataList,
        nextBid: nextBid,
        currentPrice: listBidder.data.data?.last_price || 0,
        multipleBid: listBidder.data.data?.unit_type?.multiple_bid || 0,
        currentBidderType: listBidder.data.data?.current_nipl_type,
        key: this.state.key + 1,
        countDown: this.secondsToTime(time_diff),
        disabledHentikan: false,
      });
    } catch (error) {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: error?.message,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: async () => {
              if (error?.code === 401) {
                this.props.history.replace('/logout');
              }
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  loadData = async () => {
    this.setState({ loading: true });
    try {
      this.updateAll();
    } catch (error) {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: error?.message,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: async () => {
              if (error?.code === 401) {
                this.props.history.replace('/logout');
              }
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  onStartAuction = async (idUnit) => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const response = await this.props.onStartAuction(id, idUnit);
    if (response?.data?.status_code === 401) {
      this.props.history.push('/login');
      window.location.reload();
    }
    if (response.status === 'error') {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: response.data,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  onPendingAuction = async () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const response = await this.props.onPendingAuction(id);
    if (response?.data?.status_code === 401) {
      this.props.history.push('/login');
      window.location.reload();
    }
    if (response.status === 'error') {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: response.data,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  onStartPendingAuction = async () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const response = await this.props.onStartPendingAuction(id);
    if (response?.data?.status_code === 401) {
      this.props.history.push('/login');
      window.location.reload();
    }
    if (response.status === 'error') {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: response.data,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  onFinishAuction = async () => {
    const id = this.props.match.params.id;
    const response = await this.props.onFinishAuction(id);
    if (response?.data?.status_code === 401) {
      this.props.history.push('/login');
      window.location.reload();
    }
    if (response.status === 'error') {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: response.data,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    } else {
      if (this.state.currentBidderType === 'Offline Bidder') {
        this.setFinishSold(id);
      } else if (this.state.currentBidderType) {
        // console.log('else if');
        this.setState({
          modalHentikan: {
            show: true,
            body: '',
            type: 'SOLD',
          },
        });
      } else {
        this.setState({
          modalHentikan: {
            show: false,
            body: '',
            type: 'NOT_SOLD',
          },
          modalPemenang: {
            show: false,
          },
        });
      }
    }
  };

  setFinishSold = async (id) => {
    const listBidder = await this.props.onGetOfflineNipl(id);
    if (listBidder?.data?.status_code === 401) {
      this.props.history.push('/login');
      window.location.reload();
    }
    if (listBidder.status === 'error') {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: listBidder.data,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    } else {
      this.setState({
        modalHentikan: {
          show: true,
          body: listBidder.data.data,
          type: 'OFFLINE',
          onSubmit: (buyer_nipl_id) => {
            this.onFinishSold(buyer_nipl_id);
          },
        },
      });
    }
  };

  onSubmitBid = async () => {
    const id = this.props.match.params.id;
    const response = await this.props.onSubmitBid(id, this.state.nextBid);
    if (response?.data?.status_code === 401) {
      this.props.history.push('/login');
      window.location.reload();
    }
    if (response.status === 'error') {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: response.data,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  onFloor = async () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const response = await this.props.onFloor(id);
    if (response?.data?.status_code === 401) {
      this.props.history.push('/login');
      window.location.reload();
    }
    if (response.status === 'error') {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: response.data,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
    this.setState({ loading: false });
  };

  onNotSold = async () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const isAutopilot = this.state.isAutopilot === true ? 1 : 0;
    const response = await this.props.onNotSold(id, isAutopilot);
    if (response?.data?.status_code === 401) {
      this.props.history.push('/login');
      window.location.reload();
    }
    if (response.status === 'error') {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: response.data,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  // onFinishAutomation = async () => {
  //   if (this.state.currentBidderType) {
  //     const id = this.props.match.params.id;
  //     const isAutopilot = this.state.isAutopilot === true ? 1 : 0;
  //     if (this.state.currentBidderType !== 'Offline Bidder') {
  //       this.setState({ loading: true });
  //       const soldResponse = await this.props.onSold(id, null, isAutopilot);
  //       if (soldResponse?.data?.status_code === 401) {
  //         this.props.history.push('/login');
  //         window.location.reload();
  //       }
  //       if (soldResponse.status === 'error') {
  //         if (this._mounted) {
  //           this.setState({
  //             loading: false,
  //             modal: {
  //               show: true,
  //               title: 'Error',
  //               body: soldResponse.data,
  //               buttonText: 'OK',
  //               cancelText: false,
  //               onSubmit: () => {
  //                 this.setState({
  //                   modal: {
  //                     ...this.state.modal,
  //                     show: false,
  //                   },
  //                 });
  //               },
  //             },
  //           });
  //         }
  //       }
  //     }
  //   } else {
  //     const id = this.props.match.params.id;
  //     const isAutopilot = this.state.isAutopilot === true ? 1 : 0;
  //     const notSoldResponse = await this.props.onNotSold(id, isAutopilot);
  //     if (notSoldResponse?.data?.status_code === 401) {
  //       this.props.history.push('/login');
  //       window.location.reload();
  //     }
  //     if (notSoldResponse.status === 'error') {
  //       if (this._mounted) {
  //         this.setState({
  //           loading: false,
  //           modal: {
  //             show: true,
  //             title: 'Error',
  //             body: notSoldResponse.data,
  //             buttonText: 'OK',
  //             cancelText: false,
  //             onSubmit: () => {
  //               this.setState({
  //                 modal: {
  //                   ...this.state.modal,
  //                   show: false,
  //                 },
  //               });
  //             },
  //           },
  //         });
  //       }
  //     }
  //   }
  // };

  onSold = async () => {
    const id = this.props.match.params.id;
    const isAutopilot = this.state.isAutopilot === true ? 1 : 0;
    if (this.state.currentBidderType === 'Offline Bidder') {
      const listBidder = await this.props.onGetOfflineNipl(id);
      if (listBidder?.data?.status_code === 401) {
        this.props.history.push('/login');
        window.location.reload();
      }
      if (listBidder.status === 'error') {
        if (this._mounted) {
          this.setState({
            loading: false,
            modal: {
              show: true,
              title: 'Error',
              body: listBidder.data,
              buttonText: 'OK',
              cancelText: false,
              onSubmit: () => {
                this.setState({
                  modal: {
                    ...this.state.modal,
                    show: false,
                  },
                });
              },
            },
          });
        }
      } else {
        this.setState({
          modalPemenang: {
            show: true,
            body: listBidder.data.data,
            onSubmit: (buyer_nipl_id) => {
              this.onSubmitWinner(buyer_nipl_id);
              this.setState((prevState) => {
                return {
                  modalPemenang: {
                    ...prevState.modalPemenang,
                    show: false,
                  },
                  loading: true,
                };
              });
            },
          },
        });
      }
    } else {
      this.setState({ loading: true });
      const response = await this.props.onSold(id, null, isAutopilot);
      if (response.status === 'error') {
        if (this._mounted) {
          this.setState({
            loading: false,
            modal: {
              show: true,
              title: 'Error',
              body: response.data,
              buttonText: 'OK',
              cancelText: false,
              onSubmit: () => {
                this.setState({
                  modal: {
                    ...this.state.modal,
                    show: false,
                  },
                });
              },
            },
          });
        }
      }
    }
  };

  onSubmitWinner = async (buyer_nipl_id) => {
    const id = this.props.match.params.id;
    const isAutopilot = this.state.isAutopilot === true ? 1 : 0;
    const response = await this.props.onSold(id, buyer_nipl_id, isAutopilot);
    if (response?.data?.status_code === 401) {
      this.props.history.push('/login');
      window.location.reload();
    }
    if (response.status === 'error') {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: response.data,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    }
  };

  onFinishSold = async (buyer_nipl_id) => {
    const id = this.props.match.params.id;
    const response = await this.props.onFinishSold(id, buyer_nipl_id);
    if (response?.data?.status_code === 401) {
      this.props.history.push('/login');
      window.location.reload();
    }
    if (response.status === 'error') {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            title: 'Error',
            body: response.data,
            buttonText: 'OK',
            cancelText: false,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
      }
    } else {
      this.setState({
        modalHentikan: {
          show: true,
          body: '',
          type: 'SOLD',
        },
      });
    }
  };

  secondsToTime(secs) {
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let run = true;
    if (secs === 0) {
      run = false;
    }

    let obj = {
      minutes: minutes,
      seconds: seconds,
      run: run,
    };
    if (this.state.status === 'PENDING') {
      return {
        ...this.state.countDown,
        seconds: this.state.countDown.seconds + 1,
        run: false,
      };
    }
    return obj;
  }

  onChangeLot = (val) => {
    this.setState({
      idLotManual: val.target.value,
    });
  };

  render() {
    return (
      <div className="pb-4">
        <DotLoaderOverlay loading={this.state.loading} overlayColor="rgba(0,0,0,0.1)" color={'#CF1701'} size={30} />
        <Modal
          show={this.state.status === 'PENDING'}
          noFooter={true}
          closeModal={() => {}}
          body={
            <div className="w-[350px] flex items-center flex-col">
              <img className="w-[80px] h-[80px] mb-6" alt="pause" src={require('../../assets/images/circle-pause.gif')} />
              <div className="font-bold text-xl mb-2">Lelang Dijeda</div>
              {this.state.status === 'PENDING' && !this.state.auction.current_stock_unit_id ? (
                <>
                  <div className=" text-[#646566] text-sm">Pilih Lot dan klik tombol lanjutkan lelang untuk melanjutkan lelang</div>
                  <div className="relative p-4 flex-auto w-[100%] text-left">
                    <div className="mb-2 text-sm font-semibold">
                      Pilih Lot Manual <span className="text-primary">*</span>
                    </div>
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      onChange={(data) => this.onChangeLot(data)}
                    >
                      <option value="">-Pilih Lot Manual Selanjutnya-</option>
                      {this.state.listLot.map((data, idx) => (
                        <option key={`select-lot-${idx}`} value={data.id}>
                          Lot - {this.state.auction?.auction_lane_name}
                          {data?.lot_number}
                        </option>
                      ))}
                    </select>
                  </div>
                  <Button
                    className="w-[80%] py-2 mb-4"
                    title={
                      <div className="flex items-center justify-center">
                        <Play />
                        <span className="ml-2">Lanjutkan Kembali</span>
                      </div>
                    }
                    onClick={() => this.onStartAuction(this.state.idLotManual)}
                  />
                  <div className=" text-[#646566] text-sm">atau</div>
                  <Button
                    className="w-[80%] py-2 mt-4"
                    title={
                      <div className="flex items-center justify-center">
                        <Stop />
                        <span className="ml-2">Hentikan Lelang</span>
                      </div>
                    }
                    onClick={this.onFinishAuction}
                  />
                </>
              ) : (
                <>
                  <div className=" text-[#646566] text-sm">Klik tombol di bawah untuk melanjutkan lelang</div>
                  <Button
                    className="w-[80%] py-2 mt-4"
                    title={
                      <div className="flex items-center justify-center">
                        <Play />
                        <span className="ml-2">Lanjutkan Kembali</span>
                      </div>
                    }
                    onClick={this.onStartPendingAuction}
                  />
                </>
              )}
            </div>
          }
        />
        <ModalPilihPemenang
          show={this.state.modalPemenang.show}
          onSubmit={this.state.modalPemenang.onSubmit}
          body={this.state.modalPemenang.body}
        />
        <ModalHentikanLelang
          show={this.state.modalHentikan.show}
          onSubmit={this.state.modalHentikan.onSubmit}
          type={this.state.modalHentikan.type}
          body={this.state.modalHentikan.body}
        />
        <Modal
          show={this.state.modal.show}
          closeModal={() =>
            this.setState((prevState) => {
              return { modal: { ...prevState.modal, show: false } };
            })
          }
          title={this.state.modal.title}
          body={this.state.modal.body}
          onSubmit={this.state.modal.onSubmit}
          textSubmit={this.state.modal.buttonText}
          textCancel={this.state.modal.cancelText ? 'Batal' : null}
        />
        <div className="flex px-1 md:px-24 my-8">
          <div
            className={`md:flex md:justify-between bg-white ${
              this.state.status === 'COMING' ? 'w-[100%]' : 'w-2/3 md:w-4/5'
            } py-4 p-2 rounded-lg items-center md:px-4 card`}
          >
            <span className="text-third font-semibold text-md md:text-sm">
              Jakarta | {formatDate(this.state.auction?.date_start ?? new Date(), 3)}
            </span>
            <div className="flex items-center md:mt-0 mt-1">
              <Clock className="mr-1 w-4 md:mr-2 md:w-6" />
              <span className="text-third text-sm">
                {(this.state.auction?.time_start ?? '').substr(0, 5)} - {(this.state.auction?.time_finish ?? '').substr(0, 5)} WIB
              </span>
            </div>
          </div>
          <div className={`flex md:justify-between ${'w-1/3 md:w-1/3'}`}>
            <Button
              bgColor="bg-white"
              className="w-1/3 ml-4 px-0 md:px-3  border border-gray-200"
              title={
                <div className="flex items-center justify-center">
                  <Stop />
                  <span className="ml-2 text-black text-sm">Hentikan</span>
                </div>
              }
              disabled={this.state.bidder.length > 0 || this.state.disabledHentikan}
              onClick={() => {
                this.setState({
                  modal: {
                    show: true,
                    title: 'Apa Anda yakin menghentikan lelang sekarang?',
                    buttonText: 'Hentikan',
                    cancelText: true,
                    onSubmit: () => {
                      if (this.state.bidder.length === 0) {
                        this.onFinishAuction();
                      }
                      this.setState((prevState) => {
                        return {
                          modal: {
                            ...prevState.modal,
                            show: false,
                          },
                        };
                      });
                    },
                  },
                });
              }}
            />

            {/* {(this.state.status === "STARTED" ||
              this.state.status === "PENDING") && ( */}
            <Button
              bgColor="bg-[#FFEBEC]"
              className="w-1/3 ml-4 px-0 md:px-3 "
              title={
                <div className="flex items-center justify-center">
                  <Pause />
                  <span className="ml-2 text-primary text-sm">Jeda</span>
                </div>
              }
              onClick={() => {
                this.setState({
                  modal: {
                    show: true,
                    title: 'Apa Anda yakin menjeda lelang sekarang?',
                    buttonText: 'Jeda',
                    cancelText: true,
                    onSubmit: () => {
                      this.onPendingAuction();
                      this.setState((prevState) => {
                        return {
                          modal: {
                            ...prevState.modal,
                            show: false,
                          },
                        };
                      });
                    },
                  },
                });
              }}
            />

            {/* {(this.state.status === "STARTED" ||
              this.state.status === "PENDING") && ( */}
            <div className="w-1/3 ml-4 px-0">
              <ToggleSwitch
                label={'Autopilot'}
                onChange={() => {
                  this.toggleAutopilot(this.props.match.params.id);
                }}
                value={this.state.isAutopilot}
              />
            </div>
          </div>
        </div>
        {this.state.status === 'CLOSED' ? (
          <div className="grid grid-cols-12 mx-2 gap-6 md:mx-24">
            <div className="grid h-[36rem] bg-primary card col-span-12 rounded-lg items-center justify-center">
              <label className="text-white text-3xl">Lelang Telah Selesai</label>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-12 mx-2 gap-6 md:mx-24">
            <div className="grid grid-rows-2 gap-y-6 col-span-12 lg:col-span-6">
              <div className="grid grid-cols-12 col-span-12 p-3 bg-white card gap-x-5">
                <div className="col-span-7">
                  {!!this.state.currentUnit?.pictures && this.state.currentUnit?.pictures?.length > 0 ? (
                    <img
                      src={this.state.currentUnit?.pictures[0]?.image_url}
                      className="rounded-lg h-[35vh] w-[100%] object-cover object-center"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = require('../../assets/images/no-image.jpg');
                      }}
                      alt="Unit"
                    />
                  ) : (
                    <img
                      src={require('../../assets/images/no-image.jpg')}
                      className="rounded-lg h-[35vh] w-[100%] object-cover object-center"
                      alt="Unit"
                    />
                  )}
                  {/*{this.state.currentUnit?.pictures?.map((picture, idx) => (*/}
                  {/*    idx === 0 ? (*/}
                  {/*        <img*/}
                  {/*            key={idx}*/}
                  {/*            src={!!picture?.image_url ? `${picture?.image_url}` : require('../../assets/images/no-image.jpg')}*/}
                  {/*            className="rounded-lg h-[35vh] w-[100%] object-cover object-center"*/}
                  {/*            onError={({ currentTarget }) => {*/}
                  {/*                currentTarget.onerror = null;*/}
                  {/*                currentTarget.src= require('../../assets/images/no-image.jpg');*/}
                  {/*            }}*/}
                  {/*            alt="Unit"*/}
                  {/*        />*/}
                  {/*    ) : null*/}
                  {/*))}*/}
                </div>
                <div className="col-span-5">
                  <div className="my-2 flex">
                    <label className="py-2 px-2 bg-primary text-white rounded-lg text-md font-semibold">
                      Lot-{this.state.data.auction_lane_name}
                      {this.state.currentUnit?.lot_number ?? '-'}
                    </label>
                    {this.state.auction?.current_stock_unit?.number_of_nipl > 1 && (
                      <label className="ml-1 py-2 px-2 bg-[#FF6A39] text-white rounded-lg text-sm font-semibold flex items-center gap-1">
                        <SpecialNiplIcon style={{ width: 14 }} />
                        Special NIPL
                      </label>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="text-third text-lg font-bold">{this.state.currentUnit?.unit_name ?? '-'}</label>
                  </div>
                  <div>
                    <label className="text-secondary text-md">Harga Dasar</label>
                  </div>
                  <div className="mb-3">
                    <label className="text-third text-lg font-bold">{formatRupiah(this.state.currentUnit?.base_price ?? '0', '.')}</label>
                  </div>
                  <div>
                    <label className="text-secondary text-md">Kelipatan Bid</label>
                  </div>
                  <div className="mb-3">
                    <label className="font-bold text-third text-lg">
                      {formatRupiah(this.state.data?.unit_type?.multiple_bid ?? '0', '.')}
                    </label>
                  </div>
                  <div>
                    <label className="text-secondary text-md">Highlight</label>
                  </div>
                  <div className="flex flex-wrap">
                    {this.state.currentUnit?.tags.map((tag) => (
                      <div key={`tag-${tag.id}`} className="bg-[#F0F2F5] p-2 text-secondary text-sm font-semibold mr-1 mt-1 rounded-md">
                        {tag.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className="grid grid-cols-12 col-span-12 gap-x-2 overflow-x-auto w-200"> */}
              <div className="grid grid-cols-12 col-span-12 gap-x-2 relative">
                {this.state.listLot.map((lot, idx) => (
                  <div
                    className={`relative col-span-6 md:col-span-4 card max-h-120 border-2 ${
                      lot?.id === this.state.currentUnit?.id && 'border-primary'
                    } 
                                            ${
                                              lot?.unit_auction_status === 'NOT_SOLD' && this.state.status === 'PENDING' && 'cursor-pointer'
                                            }
                                            hidden ${idx >= this.state.curIndex && idx <= this.state.curIndex + 1 && 'mobile:block'}
                                            hidden ${idx >= this.state.curIndex && idx <= this.state.curIndex + 2 && 'sm:block'}
                                            
                                        `}
                    key={`lot-${lot.id}`}
                    onClick={() => {
                      if (this.state.status === 'PENDING' && lot?.unit_auction_status === 'NOT_SOLD') {
                        this.setState({
                          modal: {
                            show: true,
                            title: `Apa Anda yakin mulai lelang manual lot ini?`,
                            buttonText: 'Mulai',
                            cancelText: true,
                            onSubmit: () => {
                              this.onStartAuction(lot?.id);
                              this.setState((prevState) => {
                                return {
                                  modal: {
                                    ...prevState.modal,
                                    show: false,
                                  },
                                };
                              });
                            },
                          },
                        });
                      }
                    }}
                  >
                    <div className="h-36 xl:h-48 w-[100%]">
                      <img
                        className="rounded-t-lg h-36 xl:h-48 w-[100%] object-cover object-center"
                        src={`${lot?.pictures[0]?.image_url}` ?? null}
                        alt="Car"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = require('../../assets/images/no-image.jpg');
                        }}
                      />
                    </div>
                    <div className="absolute top-2 right-2 bg-white px-2 py-1 rounded-md text-primary font-semibold card">
                      Grade {lot.unit_inspection?.grade || '-'}
                    </div>
                    <div
                      className="bg-lot-pattern bg-contain bg-no-repeat px-1 text-white font-semibold absolute"
                      style={{
                        width: '41%',
                        fontSize: 'small',
                        position: lot?.number_of_nipl > 1 ? 'absolute' : 'static',
                      }}
                    >
                      Lot - {this.state.auction?.auction_lane_name}
                      {lot?.lot_number}
                    </div>

                    {lot?.number_of_nipl > 1 && (
                      <div
                        className="bg-special-nipl-pattern bg-contain bg-no-repeat px-1 text-white font-semibold"
                        style={{ fontSize: 'small' }}
                      >
                        <span style={{ marginLeft: '38%' }}>Special NIPL</span>
                      </div>
                    )}

                    <div className="mx-1 mt-2 h-16 max-h-10 text-sm font-semibold text-secondary box-border">{lot?.unit_name}</div>
                    <label className="block mt-2 mx-1 font-semibold text-sm text-secondary">Harga Dasar</label>
                    <label className="block mx-1 mb-2 text-lg font-bold text-third">{formatRupiah(lot?.base_price ?? '0', '.')}</label>

                    {/* SOLD OUT LOT */}
                    {/* <div className="h-full w-full absolute top-0 left-0 flex items-center justify-center">
                                            <img
                                                className="w-40"
                                                src={require('../../assets/images/sold-out.png')}
                                                alt="Sold Out"
                                            />
                                        </div> */}
                  </div>
                ))}
                <MediaQuery maxWidth={639}>
                  <div
                    className={`bg-white border-primary border rounded-lg w-10 h-10 absolute left-[2px] top-[16vh] flex items-center justify-center hover:cursor-pointer ${
                      this.state.curIndex === 0 && 'bg-gray-200 hover:cursor-default'
                    }`}
                    onClick={this.state.curIndex === 0 ? () => {} : () => this.setState({ curIndex: this.state.curIndex - 1 })}
                  >
                    <ChevronLeft className="w-3 h-3" color="#1E1E1F" />
                  </div>
                  <div
                    className={`bg-white border-primary border rounded-lg w-10 h-10 absolute right-[2px] top-[16vh] flex items-center justify-center hover:cursor-pointer ${
                      this.state.curIndex + 2 >= this.state.listLot.length && 'bg-gray-200 hover:cursor-default'
                    }`}
                    onClick={
                      this.state.curIndex + 2 >= this.state.listLot.length
                        ? () => {}
                        : () => this.setState({ curIndex: this.state.curIndex + 1 })
                    }
                  >
                    <ChevronRight className="w-3 h-3" color="#1E1E1F" />
                  </div>
                </MediaQuery>
                <MediaQuery minWidth={640}>
                  <div
                    className={`bg-white border-primary border rounded-lg w-10 h-10 absolute left-[2px] top-[16vh] flex items-center justify-center hover:cursor-pointer ${
                      this.state.curIndex === 0 && 'bg-gray-200 hover:cursor-default'
                    }`}
                    onClick={this.state.curIndex === 0 ? () => {} : () => this.setState({ curIndex: this.state.curIndex - 1 })}
                  >
                    <ChevronLeft className="w-3 h-3" color="#1E1E1F" />
                  </div>
                  <div
                    className={`bg-white border-primary border rounded-lg w-10 h-10 absolute right-[2px] top-[16vh] flex items-center justify-center hover:cursor-pointer ${
                      this.state.curIndex + 3 >= this.state.listLot.length && 'bg-gray-200 hover:cursor-default'
                    }`}
                    onClick={
                      this.state.curIndex + 3 >= this.state.listLot.length
                        ? () => {}
                        : () => this.setState({ curIndex: this.state.curIndex + 1 })
                    }
                  >
                    <ChevronRight className="w-3 h-3" color="#1E1E1F" />
                  </div>
                </MediaQuery>
              </div>
            </div>
            {this.state.status === 'COMING' && (
              <div className="grid-rows-2 gap-y-6 col-span-12 lg:col-span-6 flex card items-center justify-center bg-[#F5F6F7] border border-[#DCDDE0] flex-col pb-5">
                <img alt="waiting" className="w-[40px] h-[80px] mt-2" src={require('../../assets/images/sand-timer.png')} />
                <div className="h-0 font-bold text-xl mb-2">Lelang Belum Dimulai</div>
                <div className="h-0 text-[#646566]">Silahkan klik pada tombol di bawah untuk memulai lelang</div>
                <Button
                  className="w-[45%] mt-4 py-2"
                  title={
                    <div className="flex items-center justify-center">
                      <Play />
                      <span className="ml-2">Mulai Lelang</span>
                    </div>
                  }
                  onClick={() => {
                    this.setState({
                      modal: {
                        show: true,
                        title: 'Apa Anda yakin mulai lelang sekarang?',
                        buttonText: 'Mulai',
                        cancelText: true,
                        onSubmit: () => {
                          this.onStartAuction();
                          this.setState((prevState) => {
                            return {
                              modal: {
                                ...prevState.modal,
                                show: false,
                              },
                            };
                          });
                        },
                      },
                    });
                  }}
                />
              </div>
            )}
            {this.state.status !== 'COMING' && (
              <div className="grid grid-rows-2 gap-y-6 col-span-12 lg:col-span-6">
                <div className="grid grid-cols-12 gap-x-2">
                  <div className="col-span-6 card">
                    <div className="py-3 px-2 border-b border-[#E4E7EB] font-bold text-third text-lg">Proxy Bid</div>
                    <div className="overflow-y-scroll min-h-[240px] max-h-[350px]" style={{ height: window.outerHeight - 600 }}>
                      {this.state.proxyBidder?.map((proxy, idx) => (
                        <div
                          key={`proxy-${idx}`}
                          className="flex items-center justify-between p-2 text-md font-bold text-third border-b border-[#E4E7EB]"
                        >
                          <label className={'text-xs font-thin text-third'}>{proxy?.buyer?.email}</label>
                          <label className="text-md text-third font-bold">{parseInt(proxy?.offer_price).toLocaleString()}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-span-6 card">
                    <div className="py-3 px-4 border-b border-[#E4E7EB] flex items-center justify-between">
                      <label className="font-bold text-third text-lg">Bidding</label>
                      <label className="py-2 px-3 bg-primary text-white rounded-lg text-sm font-semibold">
                        Lot-{this.state.data.auction_lane_name}
                        {this.state.currentUnit?.lot_number ?? '-'}
                      </label>
                    </div>
                    <div className="overflow-y-scroll min-h-[240px] max-h-[350px]" style={{ height: window.outerHeight - 600 }}>
                      {this.state.bidder?.map((bidding, idx) => (
                        <div
                          key={`bidder-${idx}`}
                          className={`flex justify-between p-2 items-center border-b border-[#E4E7EB] ${idx === 0 ? 'bg-[#FAE8E6]' : ''}`}
                        >
                          <label className={`text-md font-bold ${idx === 0 ? 'text-primary' : 'text-third'}`}>
                            {bidding?.offer_price?.toLocaleString()}
                          </label>
                          <label className="text-xs text-third font-thin">
                            {bidding?.type === 'Online Bidder' ? bidding?.buyer_email : bidding?.type}
                          </label>
                          {/*<label className="text-sm text-third ">Online Bidder</label>*/}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="grid grid-rows-5 gap-y-4">
                  <div className="grid grid-cols-12">
                    <div className="flex items-center justify-between col-span-12 rounded-lg bg-white shadow border border-red-400">
                      <label className="text-md ml-4 font-semibold">Countdown</label>
                      <label className="text-xl mr-4 font-bold">
                        {this.state.countDown.minutes.toString().length > 1
                          ? this.state.countDown.minutes.toString()
                          : '0' + this.state.countDown.minutes.toString()}
                        :
                        {this.state.countDown.seconds.toString().length > 1
                          ? this.state.countDown.seconds.toString()
                          : '0' + this.state.countDown.seconds.toString()}
                      </label>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 row-span-4">
                    <div className="card col-span-12 px-8 items-center">
                      <div className="mb-6 mt-8 grid grid-cols-12 gap-x-3">
                        <div className="flex items-center col-span-12">
                          <div className="py-1.5 px-3 bg-[#F9FAFB] border border-[#D1D5DC] rounded-l-lg">Rp</div>
                          <div className="py-1.5 px-3 bg-white border border-[#D1D5DC] rounded-r-lg w-full">
                            {parseInt(this.state.nextBid).toLocaleString()}
                          </div>
                        </div>
                        {/* <div className="flex items-center col-span-2">
                                                <Button
                                                    className={`py-1.5 px-3 bg-white border border-[#D1D5DC] rounded-none rounded-l-lg text-third hover:cursor-pointer ${this.state.buttonDisabled && 'bg-gray-50 hover:cursor-default'}`}
                                                    title="-"
                                                    onClick={() => {
                                                        if (parseInt(this.state.currentPrice)<parseInt(this.state.nextBid)) {
                                                            this.setStdivate({nextBid: parseInt(this.state.nextBid)-parseInt(this.state.multipleBid)})
                                                        }
                                                    }}
                                                    disabled={this.state.buttonDisabled}
                                                />
                                                <Button
                                                    className={`py-1.5 px-3 bg-white border border-[#D1D5DC] rounded-none rounded-r-lg text-third hover:cursor-pointer ${this.state.buttonDisabled && 'bg-gray-50 hover:cursor-default'}`}
                                                    title="+"
                                                    onClick={() => this.setState({ nextBid: parseInt(this.state.nextBid) + parseInt(this.state.multipleBid) })}
                                                    disabled={this.state.buttonDisabled}
                                                />
                                            </div> */}
                      </div>
                      <Button
                        bgColor="bg-third"
                        className="py-2  w-full"
                        title="Tawar"
                        onClick={() => this.onSubmitBid()}
                        disabled={this.state.buttonDisabled}
                      />
                      <div className="flex items-center justify-center my-6">
                        <Button
                          bgColor="bg-white"
                          color="text-third"
                          className="py-2 border border-[#D1D5DC]"
                          title="Floor"
                          onClick={() => this.onFloor()}
                          disabled={this.state.buttonDisabled}
                        />
                        <Button
                          bgColor={`${this.state.isAutopilot ? 'bg-secondary' : 'bg-[#FF4D4D]'}`}
                          disabled={this.state.isAutopilot}
                          className={`${this.state.isAutopilot ? 'border-secondary' : 'border-[#FF4D4D]'} py-2 border mx-6`}
                          title="Belum Terjual"
                          onClick={() => this.onNotSold()}
                        />
                        <Button
                          bgColor={`${this.state.isAutopilot ? 'bg-secondary' : 'bg-success'}`}
                          disabled={this.state.isAutopilot}
                          className={`${this.state.isAutopilot ? 'border-secondary' : 'border-success'} py-2 border`}
                          title="Terjual"
                          onClick={() => this.onSold()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDetailAuction: (id) => dispatch(getDetailAuction(id)),
    onGetDetailUnit: (idAuction, idUnit) => dispatch(getDetailUnit(idAuction, idUnit)),
    onGetNextLot: (idAuction, idUnit) => dispatch(getNextLot(idAuction, idUnit)),
    onGetBidding: (idAuction, idUnit) => dispatch(getBidding(idAuction, idUnit)),
    onGetOfflineNipl: (idAuction) => dispatch(getOfflineNipl(idAuction)),
    onStartAuction: (id, idUnit) => dispatch(startAuction(id, idUnit)),
    onFinishAuction: (id) => dispatch(finishAuction(id)),
    onPendingAuction: (id) => dispatch(pendingAuction(id)),
    onStartPendingAuction: (id) => dispatch(startPendingAuction(id)),
    onSubmitBid: (id, offerPrice) => dispatch(submitBid(id, offerPrice)),
    onNotSold: (id) => dispatch(notSold(id)),
    onSold: (id, buyerNiplId) => dispatch(sold(id, buyerNiplId)),
    onFloor: (id) => dispatch(floor(id)),
    onFinishSold: (id, buyerNiplId) => dispatch(finishSold(id, buyerNiplId)),
    onAuctionAutopilot: (id) => dispatch(auctionAutopilot(id)),
    onLogout: () => dispatch(logout()),
  };
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    tokenType: state.auth.tokenType,
    url: state.config.url,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveBidding);
