import React from 'react';
import { Link } from 'react-router-dom';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { ReactComponent as ChevronDown } from '../assets/icons/chevron-down.svg';
import { ReactComponent as ListMenu } from '../assets/icons/list-menu.svg';

const TopNav = props => {

    const { buttonProps, isOpen } = useDropdownMenu(1);

    const UserNav = useDropdownMenu(1);

    const navItems = [
        {
            "display_name": "Beranda",
            "route": "/",
            "class": "nav_beranda",
            multiple: ['beranda']
        },
        {
            "display_name": "Riwayat",
            "route": "/riwayat",
            "class": "nav_riwayat"
        }
    ]

    function checkActiveItem(navItems) {
        const pathname = props.location.pathname.split("/");
        return navItems.findIndex(item => {
            const route = item.route.split("/");
            return route[1] === pathname[1] || (item.multiple && handleCheck(item.multiple, pathname[1]))
        });
    }

    function handleCheck(array, val) {
        return array.some(item => item === val);
    }

    const activeItem = checkActiveItem(navItems);
    
    return (
        <div className="fixed w-full z-10">
            <div className="flex bg-white px-2 py-0.5 h-18 justify-between items-center">
                <div className="left pl-1 md:pl-20">
                    <img 
                        className="w-32"
                        src={require('../assets/images/logo-horizontal.png')}
                        alt="Caready Logo"
                    />
                </div>
                <nav className="right pr-1 md:pr-20 flex items-center">
                    {navItems.map((item, index) => (
                        <Link 
                            key={index}
                            className={`px-2 ml-2 font-semibold hidden md:block hover:text-primary ${activeItem === index && 'text-primary'}`}
                            to={item.route}
                        >
                            {item.display_name}
                        </Link>
                    ))}
                    <div {...buttonProps} className="md:hidden">
                        <ListMenu 
                            color="#687083"
                            width="15"
                        />
                    </div>
                    
                    <div {...UserNav.buttonProps} className="flex items-center border-l ml-6 my-1 py-1 hover:bg-gray-200 hover:cursor-pointer">
                        <img 
                            className="w-8 ml-2 mr-2 md:mr-4"
                            src={props.profile?.image ?? require('../assets/images/profile.png')}
                            alt="Profile"
                        />
                        <span className="mr-4 text-secondary hidden md:block">{props.profile.name}</span>
                        <ChevronDown 
                            className="mr-2"
                            color="#687083"
                        />
                    </div>
                </nav>            
            </div>
            <div className={`origin-top-right absolute right-2 md:mr-20 mr w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${!isOpen? "hidden" : ""}`}>
                {navItems.map((item, index) => (
                    <Link 
                        key={index}
                        className={`block px-4 py-2 text-md hover:text-primary ${activeItem === index && 'text-primary'}`}
                        to={item.route}
                    >
                        {item.display_name}
                    </Link>
                ))}
            </div>
            <div className={`origin-top-right absolute right-0 md:mr-20 mr w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${!UserNav.isOpen? "hidden" : ""}`}>
                <div className="py-2 px-4 border-b-2 md:hidden">{props.profile.name}</div>
                <Link className="block px-4 py-2 text-md text-secondary hover:bg-gray-200 rounded-b-md" to="/logout" >Keluar</Link>
            </div>
        </div>
    )
}

export default TopNav;