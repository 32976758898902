/* eslint-disable no-undef */
import React, { useState } from 'react';

const TestFe = ({ error }) => {
  const [arrayVar2, setArrayVar2] = useState(undefined);

  const klik = () => {
    setArrayVar2('tes');
  };
  return (
    <div>
      {error && (
        <>
          {dummyVariableNotDefined6}

          {Array.isArray(arrayVar2) &&
            arrayVar2.map((a, index) => (
              <span onClick={klik} key={index}>
                {a}
              </span> // Add a unique key when using map
            ))}
        </>
      )}
    </div>
  );
};

export default TestFe;
