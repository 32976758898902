import { combineReducers } from 'redux';

import configReducer from './config';
import authReducer from './auth';

const reducers = combineReducers({
    config: configReducer,
    auth: authReducer
})

export default reducers;