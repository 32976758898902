import React from 'react';

const TextInput = props => {

    const { lefticon, righticon, label, labelstyle, containerstyle, iconStyle, type } = props;

    const classReadOnly = props.readOnly? 'textinput-read-only' : '';

    const newProps = { ...props };

    delete newProps.lefticon;
    delete newProps.righticon;
    delete newProps.label;
    delete newProps.labelstyle;
    delete newProps.containerstyle;
    delete newProps.iconStyle;

    if(type && type === 'textarea'){
        return (
            <div className="w-full">
            {label && <label className="textinput-label" style={labelstyle}>{label}</label>}
                <div className={`textinput-container ${classReadOnly} ${props.containerclassname}`} style={containerstyle}>
                    {lefticon && (
                        <div className="textinput-lefticon" style={iconStyle}>
                            {lefticon}
                        </div>
                    )}
                    <textarea 
                        className={`textinput ${classReadOnly} ${props.className}`}
                        {...props}
                        
                    />
                    {righticon && (
                        <div className="textinput-righticon" style={iconStyle}>
                            {righticon}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="w-full">
            {label && <label className="textinput-label" style={labelstyle}>{label}</label>}
            <div className={`flex w-full items-center rounded-md border my-2 border-gray-200 ${classReadOnly} ${props.containerclassname}`} style={containerstyle}>
                {lefticon && (
                    <div className="flex h-full ml-2.5" style={iconStyle}>
                        {lefticon}
                    </div>
                )}
                <input 
                    className={`flex flex-1 border-0 px-2.5 m-1 font-sourceSansPro focus:outline-none focus:border-none ${classReadOnly} ${props.className}`}
                    {...props}
                />
                {righticon && (
                    <div className="flex h-full mr-2.5" style={iconStyle}>
                        {righticon}
                    </div>
                )}
            </div>
        </div>
    )
}

export default TextInput;