import * as actionTypes from "../actionTypes";

const initialState = {
    tokenType: null,
    token: null,
    isFirstLoggedIn: false,
    profile: {
        id: null,
        name: null,
        phone: null,
        image: null
    }
}

const authReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.AUTHENTICATE: {
            return {
                ...state,
                tokenType: action.tokenType,
                token: action.token,
                isFirstLoggedIn: true
            }
        }
        case actionTypes.CHANGE_FIRST_LOGGEDIN: {
            return {
                ...state,
                isFirstLoggedIn: action.isFirstLoggedIn
            }
        }
        case actionTypes.GET_DATA_PROFILE: {
            return {
                ...state,
                profile: {
                    ...action.profile
                }
            }
        }
        case actionTypes.LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export default authReducer;